import React, { useState, useEffect } from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { postSurvey, putSurvey } from "../store/actions/surveyList";
import { fetchTargetFail, dismissError } from "../store/actions/target";
import LaunchTemplate from './TemplateSteps/LaunchTemplate'
import ReminderTemplate from './TemplateSteps/ReminderTemplate'


import '../styles/global.css'

import { v4 as uuidv4 } from "uuid";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  Label,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import "../styles/customStyles.css";
import "../styles/global.css";
import { usePersistedState } from "../helpers/usePersistedState";


const Surveys = (props) => {
  const Dispatch = useDispatch();
  const data = useSelector((state) => state.surveyList.recent_survey_id);

  const [nameError, setNameError] = useState("");
  const [targetError, setTargetError] = useState("");
  const [interfaceTypeError, setInterfaceTypeError] = useState("");
  const [startDateError, setStartdateError] = useState("");
  const [endDateError, setEnddateError] = useState("");
  const [welcomeError, setWelcomeError] = useState("");
  const [exitError, setExitError] = useState("");

  const [name, setName] = useState("");
  const [target, setTarget] = useState("");
  const [interfaceType, setInterfaceType] = useState("");
  const [startDate, setStartdate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [welcome, setWelcome] = useState("");
  const [exit, setExit] = useState("");
  const [selectedLaunch, setSelectedLaunch] = useState("");
  const [selectedReminder, setSelectedReminder] = useState("");


  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);




  useEffect(() => {
    if (props.survey) {
      props.survey.company_target_id = parseInt(props.survey.company_target_ids);

      setName(props.survey.survey_name || 'name');
      setTarget(props.survey.company_target_id || 18);
      setInterfaceType(props.survey.survey_type);
      setStartdate(props.survey.start);
      setEnddate(props.survey.end);
      setWelcome(props.survey.welcome);
      setExit(props.survey.exit);
      setSelectedLaunch({value:props.survey.templates[0].id, label:props.survey.templates[0].template });
      setSelectedReminder({value:props.survey.templates[1].id, label:props.survey.templates[1].template });
    }
  }, [props.survey]);

  const validateStep1 = () => {
    if (name.length < 1) {
      setNameError("has-danger");
    }
    if (target.length < 1) {
      setTargetError("has-danger");
    }
    if (interfaceType.length < 1) {
      setInterfaceTypeError("has-danger");
    }
  };

  const validateStep2 = () => {
    if (welcome.length < 1) {
      setWelcomeError("has-danger");
    }
    if (exit.length < 1) {
      setExitError("has-danger");
    }
  };

  const validateStep3 = () => {
    if (startDate.length < 1) {
      setStartdateError("has-danger");
    }
    if (endDate.length < 1) {
      setEnddateError("has-danger");
    }
    
  };
  
  const onGoback = () => {
    if (step2) {
      setStep1(!step1);
      setStep2(!step2);
    }
    if (step3) {
      setStep2(!step2);
      setStep3(!step3);
    }
    if (step4) {
      setStep3(!step3);
      setStep4(!step4);
    }
    if (step5) {
      setStep4(!step4);
      setStep5(!step5);
    }
  };
  
  const OnProceedStep = () => {
    if (step1) {
      if (name && target && interfaceType) {
        setStep1(!step1);
        setStep2(!step2);
      } else {
        validateStep1();
      }
    }
    if (step2) {
      if (welcome && exit) {
        setStep2(!step2);
            setStep3(!step3);
        
        
          } else {
            validateStep2();
          }
        }
        if (step3) {
          if (startDate && endDate) {
        
        setStep3(!step3);
        setStep4(!step4);
           
          } else {
            validateStep3();
          }
        }
        if (step4) {
          if (true) {
            setStep4(!step4);
            setStep5(!step5);
            
          } else {
            validateStep1();
          }
        }
        if (step5) {
          if (true) {
            setStep1(true);
            setStep2(false);
            setStep3(false);
            setStep4(false);
            setStep5(false);
            if (!props.survey) {
              
              const surveyDetails = {
               
                survey_name: name,
                company_target_ids : target,
                survey_type_id: interfaceType,
                start: startDate,
                end: endDate,
                welcome: welcome,
                launch_template_id: selectedLaunch.value,
                reminder_template_id: selectedReminder.value,
                exit: exit,
                
              };
              Dispatch(postSurvey(surveyDetails));
              props.history.push({
                pathname: `/surveys`,
                });
              } else {
                const surveyDetails = {
                  survey_name: name,
                  company_target_ids : target,
                  survey_type_id: interfaceType,
                  start: startDate,
                end: endDate,
                  welcome: welcome,
                  launch_template_id: selectedLaunch.value,
                  reminder_template_id: selectedReminder.value,
                  exit: exit,
                };
                Dispatch(putSurvey(surveyDetails,props.survey.id));
                  props.history.push({
                      pathname: `/surveys`,
                    });
                }
          } else {
            validateStep1();
          }
        }
      };
      
      const onValueChange = (e) => {
    if (step1) {
     
      if (e.target.name === "name") {
        setName(e.target.value);
        setNameError("has-success");
      }
      if (e.target.name === "target") {
        setTarget(e.target.value);
        setTargetError("has-success");
       
      }
      if (e.target.name === "interfaceType") {
        setInterfaceType(e.target.value);
        setInterfaceTypeError("has-success");
      }
    }
    if (step2) {
      if (e.target.name === "welcome") {
        setWelcome(e.target.value);
        setWelcomeError("has-success");
      }
      if (e.target.name === "exit") {
        setExit(e.target.value);
        setExitError("has-success");
      }
    }
  };

  const handleChangeTemplate = (temp, type) =>{
    
    if(type==='launch')
    setSelectedLaunch(temp)
    if(type==='reminder')
    setSelectedReminder(temp)
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      
    }
  };

  const targetList = [];
  const interfaceList = []; 
  
  props.target.target.targets && props.target.target.targets.forEach(function(element) {
    targetList.push({ value:element.company_target_id, label: element.target })
  });
  props.interface.forEach(function(element) {
    interfaceList.push({ value:element.id, label: element.survey_type })
  });
 
  const yesterday = moment().subtract(1, 'day'); //disable past date
  const disablePastDt = current => {
    return current.isAfter(yesterday);
  };
  const disablePastlastDate = current => {
    return current.isAfter(startDate);
  };

  if (step1) {
    return (
      <div className="content" style={{ backgroundColor: "#19191A" }} data-testid="SurveyCreate-test">
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 115px)",
          }}
        >
          <Col
            md="8"
            sm="6"
            style={{ padding: 20, display: "flex", flexDirection: "column" }}
          >
            <FormGroup className={`has-label ${nameError}`}>
              <Label
                id="label_survey_name"
                style={{ color: "white", marginBlock: 10 }}
              >
                Lets start with naming your survey.
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="My first survey"
                value={name}
                onChange={(e) => onValueChange(e)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
            </FormGroup>
            <FormGroup className={`has-label ${targetError}`}>
              <Label
                id="label_survey_name"
                style={{ color: "white", marginBlock: 10 }}
              >
                Who is the target audience for your survey?
              </Label>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="target"
                options={targetList}
                
                // defaultValue={targetList.find(
                //   (obj) => obj.label === target
                // )}
                onChange={(e) => {
                  const event = {};
                            event.target = e;
                            event.target.name = "target";
                            
                  onValueChange(event)}}
                  value={props.survey?targetList.find((obj) => obj.value === target) :targetList.find((obj) => obj.value === target)}
                placeholder="Select Target"
              />
            </FormGroup>
            <FormGroup className={`has-label ${interfaceTypeError}`}>
              <Label
                id="label_survey_name"
                style={{ color: "white", marginBlock: 10 }}
              >
                Choose an interface for the survey.
              </Label>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="interfaceType"
                options={interfaceList}
                
                // defaultValue={interfaceList.find(
                //   (obj) => obj.label === interfaceType
                // )}
                onChange={(e) => {
                  const event = {};
                            event.target = e;
                            event.target.name = "interfaceType";
                            
                  onValueChange(event)}}
                value={props.survey?interfaceList.find((obj) => obj.label === interfaceType) :interfaceList.find((obj) => obj.value === interfaceType)}
                placeholder="Select Interface Type"
              />
            </FormGroup>
            
             <div className = "survey-btn">
              <Button
                color="info"
                className="btn-simple"
                onClick={() => {
                  let path = props.history.location.pathName;
                  props.history.push('/surveys')
                }}
                style={{
                  // color: "rgb(0,228,228)",
                  // borderColor: "rgb(0,228,228)",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // width: 155,
                  // height: 35,
                  // margin: 10,
                  // marginLeft: "15px",
                  // alignSelf: "flex-start",
                }}
              >
                Go Back
              </Button>{" "}
              <Button
                color="info"
                className="btn-simple"
                onClick={() => OnProceedStep()}
                style={{
                  // color: "rgb(0,228,228)",
                  // borderColor: "rgb(0,228,228)",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // width: 155,
                  // height: 35,
                  // margin: 10,
                  // marginLeft: "468px",
                  // alignSelf: "center",
                }}
              >
                Proceed
              </Button>{" "}
            </div>
          </Col>
        </Row>
        
      </div>
    );
  }
  
  if (step2) {
    return (
      <div className="content" style={{ backgroundColor: "#19191A" }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 70px)",
          }}
        >
          <Col
            md="8"
            sm="6"
            style={{ padding: 20, display: "flex", flexDirection: "column" }}
          >
            <FormGroup className={`has-label ${welcomeError}`}>
              <Label
                id="label_survey_name"
                style={{ color: "white", marginBlock: 10 }}
              >
                What should be shown as a greeting?
              </Label>
              <Input
                type="textarea"
                name="welcome"
                id="welcome"
                placeholder="Welcome to Xane AI's feedback survey"
                value={welcome}
                onChange={(e) => onValueChange(e)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
            </FormGroup>
            <FormGroup className={`has-label ${exitError}`}>
              <Label
                id="label_survey_name"
                style={{ color: "white", marginBlock: 10 }}
              >
                Enter an exit message for the survey.
              </Label>
              <Input
                type="textarea"
                name="exit"
                id="exit"
                placeholder="Thank you for your time. We look forward to improving our offerings based on your feedback."
                value={exit}
                onChange={(e) => onValueChange(e)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
            </FormGroup>
            <div className = "survey-btn">
              <Button
                color="info"
                className="btn-simple"
                onClick={() => onGoback()}
                style={{
                  // color: "rgb(0,228,228)",
                  // borderColor: "rgb(0,228,228)",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // width: 155,
                  // height: 35,
                  // margin: 10,
                  // marginLeft: "15px",
                  // alignSelf: "flex-start",
                }}
              >
                Go Back
              </Button>{" "}
              <Button
                color="info"
                className="btn-simple"
                onClick={() => OnProceedStep()}
                style={{
                  // color: "rgb(0,228,228)",
                  // borderColor: "rgb(0,228,228)",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // width: 155,
                  // height: 35,
                  // margin: 10,
                  // marginLeft: "468px",
                  // alignSelf: "center",
                }}
              >
                Proceed
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  if (step3) {
    return (
      <div className="content" style={{ backgroundColor: "#19191A" }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 70px)",
          }}
        >
          <Col
            md="8"
            sm="6"
            style={{ padding: 20, display: "flex", flexDirection: "column" }}
          >
            <FormGroup className={`has-label ${startDateError}`}>
              <Label
                id="label_survey_name"
                style={{ color: "white", marginBlock: 10 }}
              >
                Pick the launch date for the survey.
              </Label>
              <ReactDatetime
               isValidDate={disablePastDt}
                inputProps={{
                  className: "form-control",
                  placeholder: "Start Date",
                  // value: moment(startDate).utc().format('YYYY-MM-DD')
                  value: startDate?moment(new Date(startDate)).local().format('YYYY-MM-DD'):""
                  // ? startDate.substring(0,10) : ""

                }}
                timeFormat={false}
                // dateFormat={moment().format()}
                onChange={(date) => {
                
                  // 
                  if (date._d) {
                    // let Now =
                    //   date._d.getFullYear() +
                    //   "-" +
                    //   (date._d.getMonth() + 1) +
                    //   "-" +
                    //   date._d.getDate();
                    // 
                    let Now = moment.utc(date).format()

                  
                    setStartdate(Now);
                    setStartdateError("has-success");
                  }
                }}
              />
            </FormGroup>
            <FormGroup className={`has-label ${endDateError}`}>
              <Label
                id="label_survey_name"
                style={{ color: "white", marginBlock: 10 }}
              >
               Choose the last day for receiving responses.
              </Label>
              <ReactDatetime
              isValidDate={disablePastlastDate}
                inputProps={{
                  className: "form-control",
                  placeholder: "End Date",
                  value: endDate ? moment(new Date(endDate)).local().format('YYYY-MM-DD'): ""
                }}
                timeFormat={false}
                onChange={(date) => {
                  if (date._d) {
                    let EndNow
                     // let Now =
                    //   date._d.getFullYear() +
                    //   "-" +
                    //   (date._d.getMonth() + 1) +
                    //   "-" +
                    //   date._d.getDate();
                   EndNow = moment.utc(date).format()
                    setEnddate(EndNow);
                    setEnddateError("has-success");
                  }
                }}
              />
            </FormGroup>
            <div className = "survey-btn">
              <Button
                color="info"
                className="btn-simple"
                onClick={() => onGoback()}
                style={{
                  // color: "rgb(0,228,228)",
                  // borderColor: "rgb(0,228,228)",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // width: 155,
                  // height: 35,
                  // margin: 10,
                  // marginLeft: "15px",
                  // alignSelf: "flex-start",
                }}
              >
                Go Back
              </Button>{" "}
              <Button
                color="info"
                className="btn-simple"
                onClick={() => OnProceedStep()}
                style={{
                  // color: "rgb(0,228,228)",
                  // borderColor: "rgb(0,228,228)",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // width: 155,
                  // height: 35,
                  // margin: 10,
                  // marginLeft: "468px",
                  // alignSelf: "center",
                }}
              >
                Proceed
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  if(step4){
    return (
   
     <LaunchTemplate onGoback={onGoback} OnProceedStep={OnProceedStep} selectedLaunch={selectedLaunch} handleChangeTemplate={handleChangeTemplate}/>
   
    )
 }
  if(step5){
     return (
       
        <ReminderTemplate onGoback={onGoback} OnProceedStep={OnProceedStep} selectedReminder={selectedReminder} handleChangeTemplate={handleChangeTemplate}/>
    
     )
  }
 
};
export default Surveys;
