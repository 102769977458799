const INITIAL_STATE = {
    loadingINTERFACE: true,
      meta: [],
      error: false,
      error_msg: null,
    };
    
    export const interfaceList = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case "FETCH_INTERFACE_START":
         
          return {
            ...state,
            loadingINTERFACE: true,
          };
        case "FETCH_INTERFACE_SUCCESS":
          
  
          return {
            ...state,
            loadingINTERFACE: false,
            meta: action.payload
          };
        case "FETCH_INTERFACE_FAIL":
          return {
            ...state,
            error: true,
            error_msg: action.payload,
            loadingINTERFACE:false
          };
        case "DISMISS_ERROR_INTERFACE":
          return {
            ...state,
            error: false,
            error_msg: null,
          };
        default:
          return state;
      }
    };
    