import React, { Component } from "react";
import classNames from "classnames";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { deleteQuestion } from "../store/actions/questionList";
import ReactBSAlert from "react-bootstrap-sweetalert";
// react component for creating dynamic tables
import "../styles/global.css";

import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

const colors = {
	green: '#4FB32C',
	yellow: '#F8BA00',
	global: '#00E4E4',
};


function ReactTables(props) {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(null); 
  const [data, setData] = useState(
    props.Data.map((prop, key) => {
      return {
        id: key,
        serial: key+1,
        questionId : prop.id,
        theme: prop.theme,
        question:prop.question,
        questionType: prop.question_type,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add question Routing */}
            <Button
               onClick={() => {
                let obj = data.find((o) => o.id === key);
                let pathname = props.history.location.pathname;
                props.history.push({
                 pathname: `/question/${prop.id}/routing`,
                  state: { routing: true, edit: false,create:false, surveyid: props.surveyId,questionId:prop.id, surveyName: props.history.location.state.surveyName  },
                });
           

              }}
              color="info"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              {/* <i className="tim-icons icon-link-72" /> */}
              <i className="fas fa-project-diagram" />
            </Button>{" "}
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o.id === key);
                let pathname = props.history.location.pathname;
                props.history.push({
                 pathname: `/question/${prop.id}`,
                  state: { edit: true, create: false,routing:false, id: props.surveyId, questionId:prop.id, surveyName: props.history.location.state.surveyName,  },
                });
           

              }}
              color="warning"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                const  hideAlert = () => {
                  setAlert(
                    null
                  )
                };
                const successDelete = () => {
                setAlert(
                    
                      <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Deleted!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="success"
                        btnSize=""
                      >
                        Question has been deleted.
                      </ReactBSAlert>
                    )
                    var Data = data;
                    Data.find((o, i) => {
                      if (o.id === key) {
                        dispatch(deleteQuestion(prop.id))
                        data.splice(i, 1);
                      
                        return true;
                      }
                      return false;
                    });
                    setData(data);
                  
                };
                
                const warningWithConfirmMessage = () => {
                  setAlert(
                       <ReactBSAlert
                         warning
                         style={{ display: "block", marginTop: "-100px" }}
                         title="Are you sure?"
                         onConfirm={() => successDelete()}
                         onCancel={() => hideAlert()}
                         confirmBtnBsStyle="success"
                         cancelBtnBsStyle="danger"
                         confirmBtnText="Yes, delete it!"
                         cancelBtnText="Cancel"
                         showCancel
                         btnSize=""
                       >
                         You will not be able to recover this Question!
                       </ReactBSAlert>
                     )
                 
                 };
               
              
                  warningWithConfirmMessage()



               
              }}
              color="danger"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>{" "}
          </div>
        ),
      };
    })
  );

  const  filterComponent = ({ filter, onChange }) => {
    return (
      <input
        onChange={(event) => onChange(event.target.value)}
        value={filter ? filter.value : ''}
        placeholder="&#61442;  Search"
        style={{
          width: '90%',
          color: 'white',
          //fontWeight: "bold",
          fontFamily: 'FontAwesome,sans-serif',
        }}
      />
    );
  };
  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
  };

  return (
    
    <>
      <div
        className="content"
        data-testid="QuestionList-test"
        // style={{
        //   paddingTop: "80px",
        //   paddingRight: "70px",
        //   paddingBottom: "30px",
        //   paddingLeft: "70px",
        // }}
      > {alert}
       <h1 style={{ color: colors.global, fontWeight: "bold",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between", }}> {props.history.location.state.surveyName}</h1>
        <Row className="mt-0">
          <Col xs={12} md={12}>
            <Card>
            
              <CardHeader style={{
          background: '#262a31',
        }}>
                <CardTitle
                  tag="h2"
                  style={{
                    fontWeight: "bold",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ color: colors.global }}> <small>QUESTIONS</small></p>{" "}
                  <Button
											className="btn-simple"
											style={{
												color: colors.global,
												borderColor: colors.global,
                        border: 'none'

											}}
                      onClick={() => {
                        let path = props.history.location.pathName;
                        props.history.push('/surveys')
                      }}
										>
											View Surveys
										</Button>
                </CardTitle>
               
              </CardHeader>
              <CardBody>
              <Button
                    className="btn-simple"
                    style={{
                      color: colors.global,
                      borderColor: colors.global,
                    }}
                    onClick={() => {
                      props.history.push({
                        pathname: `/survey/${props.surveyId}/Create-questions`,
                         state: {id: props.surveyId, edit: false,routing:false, create:true,surveyName: props.history.location.state.surveyName},
                       });
                    }}
                  >
                    <i className="fa fa-plus" /> Add Question
                  </Button>
                <ReactTable
                defaultFilterMethod={(filter, row) =>
												filterCaseInsensitive(filter, row)
											}
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                        Header: "S.No",
                        accessor: "serial",
                        sortable: false,
                        filterable: false,
                        width: 60
                      },
                      {
                        Header: "question",
                        accessor: "question",
                        sortable: false,
                        filterable: true,
                        width: 500,
                        style: { whiteSpace: "unset" },
                        Filter: (props) => filterComponent(props),
                      },
                    {
                      Header: "theme",
                      accessor: "theme",
                      sortable: false,
                      filterable: false,
                      width: 200,
                      style: { whiteSpace: "unset" },
                    },
                    {
                      Header: "questionType",
                      accessor: "questionType",
                      sortable: false,
                      filterable: false,
                      // width: 100
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ReactTables;
