import { requestApi } from '../../config/apiHandler';
import { signOut } from '../actions/auth';


export const fetchQuestionStart = () => {
	return {
		type: 'FETCH_QUESTION_START',
	};
};

export const fetchQuestion = (id) => {
	
	return async (dispatch) => {
		dispatch(fetchQuestionStart());

		const response = await requestApi(`/questions?survey_id=${id}`, 'GET', {});
		if (response.code == 'success') {
			dispatch({
				type: 'FETCH_QUESTION_SUCCESS',
				payload: response.data,
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};

export const onError = (error) => {
	return {
		type: 'ON_ERROR',
		payload: error,
	};
};

export const dismissQuestionError = () => {
	return {
		type: 'DISMISS_QUESTION_ERROR',
	};
};

export const postQuestionStart = () => {
	return {
		type: 'POST_QUESTION_START',
	};
};

export const putQuestionStart = () => {
	return {
		type: 'PUT_QUESTION_START',
	};
};

export const postQuestion = (Data) => {
	return async (dispatch) => {
		dispatch(postQuestionStart());
		
			const response = await requestApi('/questions', 'POST', JSON.stringify(Data));
			if (response.code == 'success') {
				dispatch({
					type: 'POST_QUESTION_SUCCESS',
				});
			} else if (response.code == 'authn_fail') {
				dispatch(signOut());
			} else {
				dispatch(onError(response.message));
			}
		
			}
		}
	

	// return {
	//   type: "POST_QUESTION_SUCCESS",
	// };


export const putQuestion = (id, data) => {
	
	return async (dispatch) => {
		dispatch(putQuestionStart());
        // JSON.stringify(data)
		// let params = JSON.parse(data);
		// params.id = id;

		//params = JSON.stringify

		const response = await requestApi(`/questions/${id}`, 'PUT', JSON.stringify(data));
		if (response.code == 'success') {
			dispatch({
				type: 'PUT_QUESTION_SUCCESS',
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};

// return {
//   type: "POST_QUESTION_SUCCESS",
// };



export const getQuestionById = (id) => {
	return async (dispatch) => {
		dispatch({
			type: 'GET_QUESTION_BY_ID_START',
		});

		const response = await requestApi(`/questions/${id}`, 'GET', {});
		if (response.code == 'success') {
			dispatch({
				type: 'GET_QUESTION_BY_ID_SUCCESS',
				payload: response.data,
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};

export const putQuestionRoutingStart = () => {
	return {
		type: 'PUT_QUESTION_ROUTING_START',
	};
};

export const putQuestionRouting = (id, data) => {
	return async (dispatch) => {
		dispatch(putQuestionRoutingStart());
        // JSON.stringify(data)
		// let params = JSON.parse(data);
		// params.id = id;

		//params = JSON.stringify

		const response = await requestApi(`/questions/${id}`, 'PUT', JSON.stringify(data));
		if (response.code == 'success') {
			dispatch({
				type: 'PUT_QUESTION_ROUTING_SUCCESS',
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};
export const deleteQuestion = (id) => {
	return async (dispatch) => {
		dispatch({
			type: 'DELETE_QUESTION_START',
		});

		const response = await requestApi(`/questions/${id}`, 'DELETE', {});
		if (response.code == 'success') {
			
			dispatch({
				type: 'DELETE_QUESTION_SUCCESS',
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};
