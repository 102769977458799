import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
import { headers } from "../../variable";
import { DateFormatter } from "../../helpers/dateFormatter";

const UserList = (props) => {
  const [column, setColumn] = useState([]);

  useEffect(() => {
    if(props.UserList !== null){
      setColumn(
        headers.map((header, index) => {
          return {
            Header: header.label,
            id: index,
            accessor: header.key,
            sortable: true,
            filterable: true,
            Filter: (props) => filterComponent(props),
            style: { whiteSpace: "unset"},
            width:
              header.key === "email" || header.key === "join_date" ? 220 : 140,
          };
        })
      );
    }
 
  }, []);
  const  filterComponent = ({ filter, onChange }) => {
		return (
			<input
				onChange={(event) => onChange(event.target.value)}
				value={filter ? filter.value : ''}
				placeholder="&#61442;  Search"
				style={{
					width: '90%',
					color: 'white',
					fontWeight: "bold",
					fontFamily: 'FontAwesome,sans-serif',
				}}
			/>
		);
	};
  const filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
	};
  return (
    <div>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader style={{display:"flex",justifyContent:'space-between'}}>
              <CardTitle tag="h4">Employee Data</CardTitle>
<Button onClick={props.OnUploadClick} disabled={props.isUpload}>Upload</Button>
            </CardHeader>
            <CardBody>
              <ReactTable
              defaultFilterMethod={(filter, row) =>
												filterCaseInsensitive(filter, row)
											}
                data={props.userList && props.userList.map((x, i) => {
                  x.sno = i + 1;
                  if(x.join_date && x.join_date !== NaN-NaN-NaN){
                  let formatedDate = DateFormatter(x.join_date);
                  if(formatedDate!==NaN-NaN-NaN)
                    x.join_date = formatedDate
                  }
                  return x;
                })}
                filterable
                resizable={true}
                columns={
                  [
                  {
                    Header: "S.No",
                    accessor: "sno",
                    id: "sno",
                    sortable: true,
          filterable: false,
          Filter: (props) => filterComponent(props),
                  },
                  ...column,
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e) => {
                      // IMPORTANT! React-Table uses onClick internally to trigger
                      // events like expanding SubComponents and pivots.
                      // By default a custom 'onClick' handler will override this functionality.
                      // If you want to fire the original onClick handler, call the
                      // 'handleOriginal' function.
                      // delete rowInfo.original.actions
                      // if (column.Header !== 'Actions') {
                      //         props.history.push({
                      //           pathname: `/survey/${rowInfo.original.surveyId}/questions-list`,
                      //           state: { id: rowInfo.original.surveyId, surveyName: rowInfo.original.Survey_name },
                      //         });
                      //       }
                    },
                  };
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserList;
