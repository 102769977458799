import React, { Component } from "react";
import classNames from "classnames";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { deleteSurvey } from "../store/actions/surveyList";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import { Link } from "react-router-dom"

// react component for creating dynamic tables
import "../styles/global.css";
import { Menu } from '../components/Dropdown/Dropdown';


import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

const colors = {
	green: '#4FB32C',
	yellow: '#F8BA00',
	global: '#00E4E4',
};


const  filterComponent = ({ filter, onChange }) => {
  return (
    <input
      onChange={(event) => onChange(event.target.value)}
      value={filter ? filter.value : ''}
      placeholder="&#61442;  Search"
      style={{
        width: '90%',
        color: 'white',
        //fontWeight: "bold",
        fontFamily: 'FontAwesome,sans-serif',
      }}
    />
  );
};
const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
};
function ReactTables(props) {
  const Dispatch = useDispatch();
  const [alert, setAlert] = useState(null); 
  const [data, setData] = useState(
    props.Data.map((prop, key) => {

      return {
        id: prop.id,
        key: key,
        surveyId : prop.id,
        Serial: key+1,
        Survey_name: prop.survey_name,
        DateOfCreation: moment(prop.created_at).utc().format('YYYY-MM-DD'),
        welcome: prop.welcome_message,
        exit: prop.exit_message,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
           
               
            <a id="preview" target="_blank" rel="noreferrer" href={`https://dev.xane.ai/auths/web?survey_id=${prop.id}&token=${props.userToken}`}>
            <Button
               onClick={() => {
                  // document.getElementById('preview').style.color
              }}
              color="info"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              <i className="fas fa-eye" />
            </Button></a>{" "}

            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o.id === (key));
                let pathname = props.history.location.pathname;
                props.history.push({
                 pathname: `/survey/edit/${prop.id}`,
                  state: { edit: true, id: prop.id },
                });

              }}
              color="warning"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                const  hideAlert = () => {
                  setAlert(
                    null
                  )
                };
                const successDelete = () => {
                setAlert(
                    
                      <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Deleted!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="success"
                        btnSize=""
                      >
                        Survey has been deleted.
                      </ReactBSAlert>
                    )
                    var Data = data;
                Data.find((o, i) => {
                  if (o.key === key) {
                    Dispatch(deleteSurvey(o.id))
                    data.splice(i, 1);
                
                    return true;
                  }
                  return false;
                });
                setData(data);
                  
                };
                
                const warningWithConfirmMessage = () => {
                  setAlert(
                    
                       <ReactBSAlert
                         warning
                         style={{ display: "block", marginTop: "-100px" }}
                         title="Are you sure?"
                         onConfirm={() => successDelete()}
                         onCancel={() => hideAlert()}
                         confirmBtnBsStyle="success"
                         cancelBtnBsStyle="danger"
                         confirmBtnText="Yes, delete it!"
                         cancelBtnText="Cancel"
                         showCancel
                         btnSize=""
                       >
                         You will not be able to recover this Survey!
                       </ReactBSAlert>
                     )
                 
                 };
               
              
                  warningWithConfirmMessage()

              }}
              color="danger"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>{" "}
          </div>
        ),
      };
    })
  );

  return (
    
    <>
      <div
        className="content"
        // style={{
        //   paddingTop: "80px",
        //   paddingRight: "70px",
        //   paddingBottom: "30px",
        //   paddingLeft: "70px",
        // }}
        data-testid="SurveyList-test"
      >
            <h1 style={{ color: colors.global,
            fontWeight: "bold",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between", }}>YOUR SURVEYS</h1>{" "}
        {alert}
        <Row className="mt-0">
          <Col xs={12} md={12}>
            <Card>
              {/* <CardHeader style={{
          background: '#262a31',
        }}> */}
                {/* <CardTitle
                  tag="h2"
                  style={{
                    fontWeight: "bold",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ color: colors.global }}>YOUR SURVEYS</p>{" "}
                 
                </CardTitle> */}
               
              {/* </CardHeader> */}
              <CardBody>
              <Button
                    className="btn-simple"
                    style={{
                      color: colors.global,
                      borderColor: colors.global,
                    }}
                    onClick={() => {
                      let path = props.history.location.pathname;
                      props.history.push("/survey-create");
                      
                    }}
                  >
                    <i className="fa fa-plus" /> Add Survey
                  </Button>
                <ReactTable
                 defaultFilterMethod={(filter, row) =>
												filterCaseInsensitive(filter, row)
											}
                  data={data}
                  filterable
                  resizable={true}
                  columns={[
                    {
                        Header: "S.No",
                        accessor: "Serial",
                        sortable: false,
                        filterable: false,
                        width: 80,
                      },
                      {
                        Header: "date of creation",
                        accessor: "DateOfCreation",
                        sortable: true,
                        filterable: false,
                        width: 150,
                      },
                    {
                      Header: "Survey",
                      accessor: "Survey_name",
                      sortable: true,
                      filterable: true,
                      Filter: (props) => filterComponent(props),
                      style: { whiteSpace: "unset" },
                      width: 160,
                    },
                    {
                      Header: "Welcome Message",
                      accessor: "welcome",
                      sortable: false,
                      filterable: false,
                      width: 290,
                      // style: { whiteSpace: "unset" },
                    },
                    {
                      Header: "Exit Message",
                      accessor: "exit",
                      sortable: false,
                      filterable: false,
                      width: 290,
                      // style: { whiteSpace: "unset" },
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e) => {
                        // IMPORTANT! React-Table uses onClick internally to trigger
                        // events like expanding SubComponents and pivots.
                        // By default a custom 'onClick' handler will override this functionality.
                        // If you want to fire the original onClick handler, call the
                        // 'handleOriginal' function.
                        // delete rowInfo.original.actions

				if (column.Header !== 'Actions') {
                        props.history.push({
                          pathname: `/survey/${rowInfo.original.surveyId}/questions-list`,
                          state: { id: rowInfo.original.surveyId, surveyName: rowInfo.original.Survey_name },
                        });
                      }
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ReactTables;
