const INITIAL_STATE = {
    loadingUsers: true,
    postUsersLoading: true,
    checkStatusLoading: true,
      usersData: [],
      requestId: null,
      status: null,
      error: false,
      error_msg: null,
    };
    
    export const users = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case "FETCH_USERS_START":
         
          return {
            ...state,
            loadingUsers: true,
          };
        case "FETCH_USERS_SUCCESS":
          return {
            ...state,
            loadingUsers: false,
            usersData: action.payload
          };
        case "FETCH_USERS_FAIL":
          return {
            ...state,
            error: true,
            error_msg: action.payload,
            loadingUsers:false
          };
        case "DISMISS_ERROR_TARGET":
          return {
            ...state,
            error: false,
            error_msg: null,
          };
        case "POST_USERS_START":
          return {
            ...state,
            postUsersLoading: true,
            requestId: null
          };
          case "POST_USERS_SUCCESS":
            return {
              ...state,
              postUsersLoading: false,
              requestId: action.payload
            };
            case "POST_USERS_FAIL":
              return {
                ...state,
                error: true,
                error_msg: action.payload,
                postUsersLoading:false
              };
        case "CHECK_STATUS_START":
          return {
            ...state,
            checkStatusLoading: true,
            // status: null
          };
          case "CHECK_STATUS_SUCCESS":
            return {
              ...state,
              checkStatusLoading: false,
              status: action.payload
            };
            case "CHECK_STATUS_FAIL":
              return {
                ...state,
                error: true,
                error_msg: action.payload,
                checkStatusLoading:false
              };
        // case "PUT_USERS_START":
        //   return {
        //     ...state,
        //     putUsersLoading: true,
        //   };
        //   case "PUT_USERS_SUCCESS":
        //     return {
        //       ...state,
        //       putUsersLoading: false,
        //       requestId: {...action.payload}
        //     };
        //     case "PUT_USERS_FAIL":
        //       return {
        //         ...state,
        //         error: true,
        //         error_msg: action.payload,
        //         putUsersLoading:false
        //       };
         
        default:
          return state;
      }
    };
    