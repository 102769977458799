export const headers = [
    { label: 'Employee Id', key: 'employee_id' },
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' },
    { label: 'Gender', key: 'Gender' },
    { label: 'Phone', key: 'phone' },
    { label: 'Join Date (DD/MM/YYYY)', key: 'join_date' },
    { label: 'City', key: 'city' },
    { label: 'Department', key: 'department' },
    { label: 'Designation', key: 'designation' },
    { label: 'Grade', key: 'grade' },
    { label: 'Functional Unit', key: 'functional_unit' },
    { label: 'Business Unit', key: 'business_unit' },
    { label: 'Manager Employee Id', key: 'manager_employee_id' },
    { label: 'Manager First Name', key: 'manager_first_name' },
    { label: 'Manager Last Name', key: 'manager_last_name' },
  ];