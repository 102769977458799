import { lazy, Suspense } from "react";
import { useSelector } from "react-redux"

import React from "react";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Spinner } from "reactstrap";


const Surveys = lazy(() => import("./containers/SurveyListContainer"));
const Users = lazy(() => import("./containers/UserMangementContainer"));
const LazySurveys = (props) => {
    return (
      <Suspense
        fallback={
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#19191A",
            }}
          >
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              type="grow"
              size="lg"
            />
          </div>
        }
      >
        <ErrorBoundary>
          <Surveys {...props} />
        </ErrorBoundary>
      </Suspense>
    );
  };
const LazyUsers = (props) => {
    return (
      <Suspense
        fallback={
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#19191A",
            }}
          >
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              type="grow"
              size="lg"
            />
          </div>
        }
      >
        <ErrorBoundary>
          <Users {...props} />
        </ErrorBoundary>
      </Suspense>
    );
  };

   const routes = [
      {
        path: "/surveys",
        name: "Manage Surveys",
        component: LazySurveys,
        layout: "",
        icon: "fas fa-poll-h",
    
      },
      {
        path: "/users",
        name: "Manage Users",
        component: LazyUsers,
        layout: "",
        icon: "fas fa-users",
      },
    ]


  export default routes;