import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTemplate,
  postTemplate,
  putTemplate
} from "../../store/actions/template";
import "../../styles/global.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  Label,
  Input,
  Button,
  FormGroup,
} from "reactstrap";

const LaunchTemplate = (props) => {
  const Dispatch = useDispatch();
  const { template, loadingTemplate, saveTemplate, postTemplateLoading } = useSelector(
    (state) => state.template
  );

  const [templateName, setTemplateName] = useState();
  const [senderName, setSenderName] = useState();
  const [senderEmail, setSenderEmail] = useState();
  const [subject, setSubject] = useState();
  const [body, setBody] = useState();
  const [templateNameError, setTemplateNameError] = useState();
  const [senderNameError, setSenderNameError] = useState();
  const [senderEmailError, setSenderEmailError] = useState();
  const [subjectError, setSubjectError] = useState();
  const [bodyError, setBodyError] = useState();

  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDisableSave, setIsDisableSave] = useState(false);

  useEffect(() => {
    Dispatch(fetchTemplate());
  }, [Dispatch, create, edit,saveTemplate]);

  useEffect(() => {
    if(create && !postTemplateLoading && saveTemplate){
      props.handleChangeTemplate(
        {
          value: saveTemplate && saveTemplate.template && saveTemplate.template.id,
          label: saveTemplate && saveTemplate.template && saveTemplate.template.template,
        },
        "launch"
      )
      setCreate(false);
    
  }
  }, [saveTemplate]);

  useEffect(() => {
    let selectedTemp;
    selectedTemp =
      template &&
      template.templates &&
      template.templates.find((data) => data.id === props.selectedLaunch.value);

    if (selectedTemp && !create) {
      setTemplateNameError("has-success");
      setSenderNameError("has-success");
      setSenderEmailError("has-success");
      setSubjectError("has-success");
      setBodyError("has-success");
      setTemplateName(selectedTemp.template);
      setSenderName(selectedTemp.sender_name);
      setSenderEmail(selectedTemp.sender_email);
      setSubject(selectedTemp.subject);
      setBody(selectedTemp.body);
    }
    if (create) {
      setTemplateName("");
      setSenderName("");
      setSenderEmail("");
      setSubject("");
      setBody("");
    }
  }, [template, props.selectedLaunch, create]);

  const options = (temp) => {
    let data =
      template &&
      template.templates &&
      template.templates.map((data, index) => {
        if (data.template_type_id === 1)
          return { value: data.id, label: data.template };
      });

    return data && data.filter(Boolean);
  };

  const onValueChange = (e) => {
    if (e.target.name === "Template Name") {
      setTemplateNameError("has-success");
      setTemplateName(e.target.value);
    }
    if (e.target.name === "Sender Name") {
      setSenderNameError("has-success");
      setSenderName(e.target.value);
    }
    if (e.target.name === "email") {
      setSenderEmailError("has-success");
      setSenderEmail(e.target.value);
    }
    if (e.target.name === "subject") {
      setSubjectError("has-success");
      setSubject(e.target.value);
    }
    if (e.target.name === "body") {
      setBodyError("has-success");
      setBody(e.target.value);
    }
  };

  const onCreate = () => {
    setCreate(true);
  };
  const onCancel = () => {
    setCreate(false);
  };
  const onEdit = () => {
    setEdit(!edit);
  };
  const  OnSaveClick = () => {
    setIsDisableSave(true)
    if (templateName && senderName && senderEmail && subject && body) {
      const templateDetails = {
        template_type_id: 1,
        template: templateName,
        sender_name: senderName,
        sender_email: senderEmail,
        subject: subject,
        body: body,
      };
      Dispatch(postTemplate(templateDetails));

     
      
      setIsDisableSave(false)
        
    } else {
      if (!templateName) {
        setTemplateNameError("has-danger");
      }
      if (!senderName) {
        setSenderNameError("has-danger");
      }
      if (!senderEmail) {
        setSenderEmailError("has-danger");
      }
      if (!subject) {
        setSubjectError("has-danger");
      }
      if (!body) {
        setBodyError("has-danger");
      }
    }
  };
  
  const OnEditClick = () => {
    if (templateName && senderName && senderEmail && subject && body) {
      const templateDetails = {
        template_type_id: 1,
        template: templateName,
        sender_name: senderName,
        sender_email: senderEmail,
        subject: subject,
        body: body,
      };
      Dispatch(putTemplate(props.selectedLaunch.value,templateDetails ));
      
      if(!postTemplateLoading || saveTemplate)
      {
        props.handleChangeTemplate(
          {
            value: props.selectedLaunch.value,
            label: templateDetails.template,
          },
          "launch"
        )
        
      }
      setEdit(false);
        
    } else {
      if (!templateName) {
        setTemplateNameError("has-danger");
      }
      if (!senderName) {
        setSenderNameError("has-danger");
      }
      if (!senderEmail) {
        setSenderEmailError("has-danger");
      }
      if (!subject) {
        setSubjectError("has-danger");
      }
      if (!body) {
        setBodyError("has-danger");
      }
    }
  };

  return (
    <div className="content" style={{ backgroundColor: "#19191A", }}>
      <h1
        style={{
          marginLeft: "auto !important",
          paddingLeft: "40%",
        }}
      >
        Launch Template
      </h1>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          padding: "0px",
          //   border: "2px solid black",
        }}
      >
        <Col sm="10" class="m-auto"></Col>
        <Col sm="4">
          <FormGroup className={`has-label ${"nameError"}`}>
            <Label
              id="label_survey_name"
              style={{ color: "white", marginBlock: 10 }}
            >
              Select Launch Template
            </Label>
            {!loadingTemplate ? (
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="Select"
                options={options()}
                //   defaultvalue={selectedReminder}
                value={create===false ? props.selectedLaunch:null}
                placeholder="Select Template"
                onChange={(e) => {
                  setCreate(false);
                  setEdit(false);
                  props.handleChangeTemplate(e, "launch");
                }}
              />
            ) : (
              <div>
              <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
              </div>
            )}
          </FormGroup>
        </Col>
        <Col sm="2">
          <Button
           color="info"
                  className="btn-simple"
            disabled= {(edit)?true:false}
                  style={{
            color: "Yellow",
            borderColor: "Yellow",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 155,
            height: 35,
            // margin: 10,
            marginTop: "20%",
            alignSelf: "center",
          }}
            onClick={(create && !edit )? onCancel : onCreate}
          >
          {(create && !edit ) ?  <i class="tim-icons icon-simple-remove"></i>:<i class="tim-icons icon-simple-add"></i> }
        
          </Button>
        </Col>
        <Col sm="2">
        { (!create)?
          <Button
           color="info"
                  className="btn-simple"
                  style={{
                    color: "Yellow",
                    borderColor: "Yellow",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 155,
                    height: 35,
                    // margin: 10,
                    marginTop: "20%",
                    alignSelf: "center",
                  }}
            onClick={onEdit}
          >
           <i class="fas fa-pen"></i>
          </Button> : null}
        </Col>
       
        <Col sm="10">
        <hr style={{background: "#504a4a"}} />
          {" "}
          <FormGroup className={`has-label ${templateNameError}`}>
            <Label
              id="label_survey_name"
              style={{ color: "white", marginBlock: 10 }}
            >
              Template Name.
            </Label>
            <Input
disabled={create || edit?false:true}
              type="text"
              name="Template Name"
              id="Template-name"
              placeholder={"Xane Launch Template"}
              value={templateName}
              onChange={(e) => onValueChange(e)}
              // onKeyPress={(e) => handleKeyPress(e)}
            />
          </FormGroup>
        </Col>
        <Col sm="4">
          <FormGroup className={`has-label ${senderNameError}`}>
            <Label
              id="label_survey_name"
              style={{ color: "white", marginBlock: 10 }}
            >
              Sender Name.
            </Label>
            <Input
disabled={create|| edit?false:true}
              type="text"
              name="Sender Name"
              id="Sender-name"
              placeholder="Xane"
              value={senderName}
              onChange={(e) => onValueChange(e)}
              // onKeyPress={(e) => handleKeyPress(e)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className={`has-label ${senderEmailError}`}>
            <Label
              id="label_survey_name"
              style={{ color: "white", marginBlock: 10 }}
            >
              Sender Email.
            </Label>
            <Input
disabled={create|| edit?false:true}
              type="email"
              name="email"
              id="email"
              placeholder="no-reply@xane.ai"
              value={senderEmail}
              onChange={(e) => onValueChange(e)}
              // onKeyPress={(e) => handleKeyPress(e)}
            />
          </FormGroup>
        </Col>
        <Col sm="10">
          {" "}
          <FormGroup className={`has-label ${subjectError}`}>
            <Label
              id="label_survey_name"
              style={{ color: "white", marginBlock: 10 }}
            >
              Subject.
            </Label>
            <Input
disabled={create|| edit?false:true}
              type="text"
              name="subject"
              id="subject"
              placeholder="Attention !!!"
              value={subject}
              onChange={(e) => onValueChange(e)}
              // onKeyPress={(e) => handleKeyPress(e)}
            />
          </FormGroup>
        </Col>
        <Col sm="10">
          {" "}
          <FormGroup className={`has-label ${bodyError}`}>
            <Label
              id="label_survey_name"
              style={{ color: "white", marginBlock: 10 }}
            >
              Body.
            </Label>
            <Input
disabled={create|| edit?false:true}
              style={{}}
              type="textarea"
              name="body"
              id="body"
              placeholder="Dear {{first_name}},
You have been invited to fill a survey {{link}}.
Regards,
Team Xane AI"
              value={body}
              onChange={(e) => onValueChange(e)}
              // onKeyPress={(e) => handleKeyPress(e)}
            />
            <span className="form-text">
                           HINT :  First Name = <code>{"{{first_name}}"}</code>,
                            Link = <code>{"{{link}}"}</code> 
                          </span>
          </FormGroup>
        </Col>
        <Col sm="10">
        <div className = "survey-btn">
            {!create && !edit ? (
              <>
                {" "}
                <Button
                  color="info"
                  className="btn-simple"
                  onClick={() => props.onGoback()}
                  style={{
                    // color: "rgb(0,228,228)",
                    // borderColor: "rgb(0,228,228)",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // width: 155,
                    // height: 35,
                    // margin: 10,
                    // marginLeft: "15px",
                    // alignSelf: "flex-start",
                  }}
                >
                  Go Back
                </Button>{" "}
                <Button
                  color="info"
                  className="btn-simple"
                  onClick={() => props.OnProceedStep()}
                  // style={{
                  //   color: "rgb(0,228,228)",
                  //   borderColor: "rgb(0,228,228)",
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                  //   width: 155,
                  //   height: 35,
                  //   margin: 10,
                  //   // marginLeft: "468px",
                  //   alignSelf: "center",
                  // }}
                >
                  Proceed
                </Button>{" "}
              </>
            ) : (
              <Col sm="12">
                {" "}
              
                <Button
                  color="info"
                  className="btn-simple"
                  onClick={() => !edit? OnSaveClick(): OnEditClick()}
                  // disabled={isDisableSave}
                  style={{
                    color: "Yellow",
                    borderColor: "Yellow",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 155,
                    height: 35,
                    margin: 10,
                    marginLeft: "200s",
                    alignSelf: "center",
                  }}
                >
                  Save
                </Button>
              </Col>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LaunchTemplate;
