import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
export const ErrorAlert = (props) => {
  console.log(props)
  return (
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="ERROR"
      onConfirm={() => props.hide()}
      confirmBtnBsStyle="success"
      confirmBtnText="OK"
      btnSize=""
    >
      {props.message}
    </ReactBSAlert>
  );
};
