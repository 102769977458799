
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { fetchSurvey } from '../store/actions/surveyList'
import { Spinner } from 'reactstrap';

import SurveyList from '../view/SurveyList';


const SurveyListContainer = (props) => {
	const [loading, setLoading] = useState(true)
	const [isAuthenticated, setAuthenticated] = useState(() => {
		const token = localStorage.getItem("x-auth");
		return token !== null;
	});
	const Dispatch = useDispatch();
	
	
	useEffect(()=>{
		if(isAuthenticated){
			const loadSpots = async ()=> {
				setLoading(true);
				await Dispatch(fetchSurvey());
				setLoading(false)
			}
			loadSpots();
		}
	}, [Dispatch,isAuthenticated]);

	const surveyList = useSelector((state) => state.surveyList)
	// const userToken = useSelector((state) => state.auth.userToken)
	const userToken = JSON.parse(localStorage.getItem('x-auth'))
	

	if(!loading){
		
	var data = surveyList.surveyList.surveys.filter(e => e.active !== 0)
}
if(isAuthenticated===false){
	props.history.push("/login")

}

	return (loading)? <div className="content" style={{ backgroundColor: '#19191A' }}>
	<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
</div>: <SurveyList {...props} Data = {data} userToken={userToken}/>;
	
};



export default SurveyListContainer;