const requiredError = (headerName, rowNumber, columnNumber) => {
    return `<div class="red">${headerName} is required in the <strong>${rowNumber} row</strong> / <strong>${columnNumber} column</strong></div>`;
  };

  const validateError = (headerName, rowNumber, columnNumber) => {
    return `<div class="red">${headerName} is not valid in the <strong>${rowNumber} row</strong> / <strong>${columnNumber} column</strong></div>`;
  };

  const uniqueError = (headerName, rowNumber) => {
    return `<div class="red">${headerName} is not unique at the <strong>${rowNumber} row</strong></div>`;
  };

  const isEmailValid = function (email) {
    const reqExp =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const reqExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    return reqExp.test(email);
  };
  const isPhoneNoValid = function (phone) {
    let reqExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return reqExp.test(phone);
  };
  const isDateValid = function (date) {
    let reqExp = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/; // DD/MM/YYYY
    
    return reqExp.test(date);
  };

 export const CSVConfig = {
    headers: [
      { name: "Employee Id", inputName: "employee_id" },
      {
        name: "First Name",
        inputName: "first_name",
        required: true,
        requiredError,
      },
      {
        name: "Last Name",
        inputName: "last_name",
        required: true,
        requiredError,
        optional: true,
      },
      {
        name: "Email",
        inputName: "email",
        required: true,
        requiredError,
        unique: true,
        uniqueError,
        validate: isEmailValid,
        validateError,
      },
      { name: "Gender", inputName: "Gender" },
      {
        name: "Phone",
        inputName: "phone",
        unique: true,
        uniqueError,
        validate: isPhoneNoValid,
        validateError,
      },
      { name: "Join Date (DD/MM/YYYY)", inputName: "join_date", validate: isDateValid, validateError},
      { name: "City", inputName: "city" },
      { name: "Department", inputName: "department" },
      { name: "Designation", inputName: "designation",  required: true,
      requiredError,},
      { name: "Grade", inputName: "grade" },
      { name: "Functional Unit", inputName: "functional_unit" },
      { name: "Business Unit", inputName: "business_unit" },
      { name: 'Manager Employee Id', inputName: 'manager_employee_id' },
      { name: 'Manager First Name', inputName: 'manager_first_name' },
      { name: 'Manager Last Name', inputName: 'manager_last_name' },
    ],
  };
