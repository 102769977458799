export const DateFormatter =(d)=>
{
 // setdate();
 let date = new Date(d)
 let n=(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()).toString() +
 "-" +
 (date.getMonth() + 1 < 10
   ? "0" + (date.getMonth() + 1)
   : date.getMonth() + 1
 ).toString() +
 "-" +
 date.getFullYear()

return n
}