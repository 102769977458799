const axios = require("axios");
const API_URL = "https://api.surveys.xane.ai/dev";
const AUTH_URL = "https://dev.xane.ai";
const TEMPLATE_API_URL = "https://api.surveys.xane.ai/dev";

const headers = {
  "x-origin": 3,
  "x-version": 1,
  // "x-auth": "47acdb51c39911eb86a10aba2aaf05ea",
  "Content-Type":"application/json",
};

export const requestApi = async (resourcePath, method, params) => {
  let url  = API_URL + resourcePath;
  headers["x-auth"] = JSON.parse(await localStorage.getItem("x-auth"));

  if(resourcePath.includes("/templates")){
    url  = TEMPLATE_API_URL + resourcePath;
  }

  if (resourcePath == "/auths/login" || resourcePath == "/updates") {
    url  = AUTH_URL + resourcePath; 
    delete headers["x-auth"];
  }
  
  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    let response = await axios({ url, method, data: params, headers });
    return response.data;
  } else {
    let response = await axios({ url, method, headers });
    return response.data
  }
};
