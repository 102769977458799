import React, { useEffect, useState } from "react";
import Select from "react-select";
// import "./_Dropdown.css";

const customStyles = {
  control: (base, state) => ({
    ...base,
    width: "15vw",
    marginRight: '10px',
    float: 'right',
    background: "#19191A",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#fff" : "#fff",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "red" : "blue"
      background: state.isFocused ? "#2c2c2d" : "#2c2c2d"
    }
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    background: "#19191A",
    // width: 'inherited',
    
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    
  }),
  option: (provided,state) => ({
    ...provided,
    color: state.isFocused ?'black' : '#fff',
    fontWeight: 700
    
  }),
  control: provided => ({
    ...provided,
    background: 'black'
    
  }),
  singleValue: provided => ({
    ...provided,
    color: '#fff',
    fontWeight: 600,
    float: 'right'
  })
};

const Dropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState(props.selectedSurvey);
  const [selectedCycle, setSelectedCycle] = useState(props.selectedCycle);
  const options = [];
  if (props.cycle) {
    let selectedSurvey = props.surveys && props.surveys.filter((survey) => survey.id === props.selectedSurvey);
    selectedSurvey && selectedSurvey[0] && selectedSurvey[0].cycles.map((data, index) => {
        return options.push({ value: data.cycle, label: "Cycle " + data.cycle });
      });
  } else {
    props.surveys &&
      props.surveys.map((data, index) =>
        options.push({ value: data.id, label: data.survey_name })
      );
  }

  const handleChange = (event) => {
  
    if(props.cycle){
      setSelectedCycle(event.value)
      props.hancleChangeCycle(event)
      return
    }
    setSelectedOption(event.value);
    props.hancleChangeSurvey(event);
    return
  };
  if(!props.cycle){
  }

  return (
    <Select
      className="Dropdown"
      style={{ color: "red", zIndex: 100 }}
      styles={customStyles}
      value={props.cycle?options.filter(element => element.value === props.selectedCycle)[0] : options.filter(element => element.value === props.selectedSurvey)[0]}
      onChange={(event) => handleChange(event)}
      options={options}
      placeholder={props.cycle?"Select Survey Cycle":"Select Survey"}
    />
  );
};

export default Dropdown;