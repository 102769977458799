import React from 'react'
import { CSVLink } from "react-csv";

import {headers} from '../variable'

const CSV = (props) => {

  const data = []
  

    return (
        <div className="content" style ={{display:"inline"}}>
       { data? <CSVLink
                  type={props.textStyle?"none":"button"}
                  className={props.textStyle?"none":"btn btn-light"}
                  filename={`${props.fileName}.csv`}
                  data={data}
                  headers={headers}
                  target="_blank"
                  separator={","}
                  style={{
                    // background:"rgb(50, 142, 182)",
                   margin: 0,
                  //  marginLeft: "1vw",
                   borderRadius: "4px",
                   padding: "12px",
                  //  display:"inline"
                    
                     
                  }}
                >
                  {props.textStyle?"Download Template":<div className="download_style">
                    <i className="fa fa-download" /> Download Template
                  </div>}
                </CSVLink>
       :null}
        </div>
      );
}

export default CSV