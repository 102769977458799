import React, { useEffect, useState } from "react";
import { Row, Col, Button, Label, Input, Spinner, FormGroup } from "reactstrap";
import { Route, Switch, Redirect } from "react-router-dom";
import { postQuestion,putQuestionRouting,putQuestion } from "../store/actions/questionList";


import ReactBSAlert from "react-bootstrap-sweetalert";

import { useSelector, useDispatch } from "react-redux";

// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";
import { v4 as uuidv4 } from "uuid";

//CSS
import "../styles/global.css";

// wizard steps
import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";

const QuestionCreate = (props) => {
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const Dispatch = useDispatch();
  const survey = props.work;
  const id = uuidv4();
 
let qt
  if(props.match.path === '/question/:id'){
    if(props.question.question_type.toLowerCase() === 'subjective'){
      qt = 'subjective'
      
    }
    
    if(props.question.question_type.toLowerCase() === 'objective'){
    
      qt ='objective'
     
    }
    if(props.question.question_type.toLowerCase()==='multiple choice'){
     
      qt = 'multiple'
   
      
      
    }
  }

 
  var steps = props.match.path === '/question/:id/routing'
    ? [
        {
          stepName: "Routing",
          stepIcon: "fas fa-project-diagram",
          component: Step3,
          stepProps: {
            allQuestions: props.allQuestion.questions,
            editQuestionType: props.question
              ? props.question.question_type
              : null,
            question_answers: props.question
              ? props.question.answers
              : null,
            routing: true,
            question: props.question,
          },
        },
      ]
    : [
        {
          stepName: "Question",
          stepIcon: "fas fa-question",
          component: Step1,
          stepProps: {
            themes: props.themes,
            editQuestion: props.question ? props.question.question : null,
            editTheme: props.question ? props.question.category_id : null,
          },
        },
        {
          stepName: "Question Type",
          stepIcon: "tim-icons icon-bullet-list-67",
          component: Step2,
          stepProps: {
            questionType: qt
              ? qt
              : "objective",
            editQuestionType: qt
              ? qt
              : "objective",
          },
        },
        {
          stepName: "Answer Options",
          stepIcon: "tim-icons icon-align-center",
          component: Step3,
          stepProps: {
            allQuestions: props.allQuestions,
            editQuestionType:qt
            ? qt
            : "objective",
            question_answers: props.question
              ? props.question.answers
              : null,
            edit: false,
            routing: props.work.routing
          },
        },
      ];

  const showConfirmationModal = () => {
    setAlert(true);
  };

  const hideConfirmationModel = () => {
    setAlert(false);
    if(props.match.path === '/question/:id/routing'){
  
      props.history.push({
        pathname: `/survey/${props.work.surveyid}/questions-list`,
        state: { id: props.work.surveyid, surveyName:props.surveyName },
      });
    } else
    {
      if(props.location.state.edit !== true){
        props.history.push({
          pathname: `/survey/${props.match.params.id}/questions-list`,
          state: { id: props.work.id, surveyName:props.surveyName},
        
      })
      } else {
        props.history.push({
          pathname: `/survey/${props.work.id}/questions-list`,
          state: { id: props.work.id, surveyName:props.surveyName},
        
      })
      }
     
    }

  }
  function finishButtonClick(allStates) {
    localStorage.removeItem("question_type");
  
    
    if (props.match.path !== '/question/:id/routing') {
 
      let questionType = allStates["Question Type"].questionType;
      let question_answers;
      questionType === "subjective"
        ? (question_answers =
            allStates["Answer Options"].subjective_question_answers)
        : (question_answers = allStates["Answer Options"].question_answers);
            let type
        if(allStates["Question Type"].questionType ==='objective'){
            type=1
        }
        if(allStates["Question Type"].questionType ==='subjective'){
            type=2
        }
        if(allStates["Question Type"].questionType ==='multiple'){
            type=3
        }

      const questionDetails = {
        survey_id:survey.id,
        question: allStates.Question.question,
        theme_id: allStates.Question.theme,
        question_type_id: type,
        answers: question_answers,
      };

      if (props.location.state.edit === false) {
        Dispatch(postQuestion(questionDetails));
        setAlertText("Question created successfully!")
        showConfirmationModal();
      } else {
        let qId = props.question.id
        Dispatch(putQuestion(qId,questionDetails));
        setAlertText("Question edited successfully!")
        showConfirmationModal();
      }
    } else {
      var i = 0;
      var j = 0;
      let data = props.question.answers;
 
      // let RoutingData =
      // props.question.question_type.toLowerCase()=== "subjective"
      //     ? allStates["Question Detail"].subjective_question_answers[i].next_question_id
      //     : allStates["Question Detail"].question_answers[i].next_question_id;
      for (i = 0; i < props.question.answers.length; i++) {
        for ( j = 0; j < props.question.answers.length; j++) {
          data[j].next_question_id =
          props.question.question_type.toLowerCase()=== "subjective"
          ? allStates["Routing"].subjective_question_answers[0].next_question_id
          : allStates["Routing"].question_answers[j].next_question_id;
        }
      }
  
      const questionDetails = {
        answers: data
      };
      let sId = props.match.params.id
     Dispatch(putQuestionRouting(sId,questionDetails)); 
     setAlertText("Question routed successfully!")
      showConfirmationModal();
    }
  }
 

  return (
    <>
      <div className="content">
        {alert === true ? (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Good job!"
            onConfirm={() => hideConfirmationModel()}
            onCancel={() => hideConfirmationModel()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            {alertText}
          </ReactBSAlert>
        ) : null}
        <Col className="mr-auto ml-auto" md="10">
          <ReactWizard
            steps={steps}
            validate
            title="Questions"
            description={props.surveyName}
            headerTextCenter
            finishButtonClasses="btn-wd btn-info"
            nextButtonClasses="btn-wd btn-info"
            previousButtonClasses="btn-wd"
            progressbar
            color="blue"
            nextButtonText="Proceed"
            previousButtonText="Go Back"
            finishButtonText="Save Question"
            finishButtonClick={finishButtonClick}
          />
        </Col>
      </div>
    </>
  );
};

export default QuestionCreate;
