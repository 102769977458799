import React, { useState, useEffect } from "react";
import CSVFileValidator from "csv-file-validator";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from 'react-redux'
import CSV from "../components/CSV.js";
import Dropdown from "../components/Dropdown/Dropdown";
import UserList from "./userListTable/UserList";
import { fetchUsers, postUsers } from "../store/actions/users.js";
import { requestApi } from "../config/apiHandler"
import { ErrorAlert } from "../components/Alert/ErrorAlert.js";



import { Row, Col, Spinner } from "reactstrap";
import { postSurvey } from "../store/actions/surveyList.js";

const UserMangement = (props) => {
  const [userList, setUserList] = useState([]);
  const [showAlert, setShowAlert] = useState();
  const [fileUpload, setFileUpload] = useState(true)
  const [progress, setProgress] = useState(false)
  const [upload, setUpload] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  


  const [confirmation, setConfirmation] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(JSON.parse(localStorage.getItem('selectedSurvey')));
  const {loadingUsers, usersData, requestId, status, error, error_msg}= useSelector((state) => state.users)

  const Dispatch = useDispatch();

  useEffect(() => {
    if(progress===true && requestId!==null){
      const handleStatusCheck = async () => {
        // const response = {message:"failed"}
         const response = await requestApi(`/requests/${requestId}`, 'GET', {});
       if(response && response.code == 'success'){
         if(response.data.request.status==='completed'){
           setUpload(false); 
           setProgress(false)
           setConfirmation(true)
           document.getElementById('file').value= null;
           clearInterval(intervalID)
          }
        } else {
          setUpload(false); 
          setProgress(false)
          setUploadError("failed")
          document.getElementById('file').value= null;
          clearInterval(intervalID)
          
        }
      }
      let intervalID = setInterval( handleStatusCheck, 1000);
    }
    
  },[progress])

  useEffect(() => {
   if(selectedSurvey!==null){
     Dispatch(fetchUsers(selectedSurvey)) //call api to fetch user list for a particular survey
   }  
  }, [selectedSurvey,Dispatch])

  useEffect(() => {
    setUserList(usersData)
  }, [usersData])


  const handleInputChange = (event) => {
    setFileUpload(false)
    CSVFileValidator(event.target.files[0], props.CSVConfig).then((csvData) => {
      if (csvData.inValidMessages.length !== 0 || csvData.data.length === 0) {
        document.getElementById("file").value = "";
        setShowAlert(
          <SweetAlert
            confirmBtnBsStyle="info"
            title={
              <span>
                <i class="fas fa-exclamation" style={{ color: "red" }}></i>
                <small style={{ fontSize: "2.2vh" }}>
                  Please fix errors below
                </small>
              </span>
            }
            onConfirm={() => setShowAlert(null)}
          >
            <div id="invalidMessages"></div>
          </SweetAlert>
        );
        if (csvData.data.length === 0) {
          setFileUpload(true)
          document
            .getElementById("invalidMessages")
            .insertAdjacentHTML("beforeend", "No Data Found");
        }
        csvData.inValidMessages.forEach((message) => {
          document
            .getElementById("invalidMessages")
            .insertAdjacentHTML("beforeend", message);
        });
      } else {
        setUserList(csvData.data);
      }
    });
  };

  const hancleChangeSurvey = (survey) => {
    localStorage.setItem('selectedSurvey',survey.value)
    setSelectedSurvey(JSON.parse(localStorage.getItem('selectedSurvey')));
  };
  const OnUploadClick = () => {
    setFileUpload(true) // to Disable Upload button when file is not selected
    setUpload(true);
  };
  const  HandleUpload = async () => {
   await Dispatch(postUsers(userList, selectedSurvey))
    setProgress(true) // Loader while uploading
  };

  return (
    <div className="content">
      <h1>User Management</h1>
      {(uploadError)?ErrorAlert({message:uploadError, hide: ()=>{
        setUploadError(null)
      }}):null}
      {showAlert}
      {upload ? (
        <SweetAlert
          showCancel={!progress}
          showConfirm={!progress}
          confirmBtnText="Continue"
          confirmBtnBsStyle="secondary"
          cancelBtnBsStyle="info"
          title={(progress)?"Uploading...":"Upload"}
          closeOnClickOutside={false}
          onConfirm={() => HandleUpload()}
          onCancel={() => setUpload(false)}
        >
        {(progress)? <strong style={{ fontWeight: 600 }}>This may take some time. Please wait. <div className="content" style={{ }}>
		<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
	</div></strong>:<>
          <strong style={{ fontWeight: 600 }}>{userList.length}</strong> users
          will be linked to{" "}
          <strong style={{ fontWeight: 600 }}>{props.surveys && props.surveys.filter(element => element.id === selectedSurvey)[0].survey_name}</strong>
       </> }
        </SweetAlert>
      ) : null}
      <hr className="users-hr" style={{ background: "red !important" }} />
      <Row className="mt-4">
        <Col xs="2">
          <CSV fileName="Format" />
        </Col>
        <Col xs="3">
          <div className="FileDiv">
            <input
              type="file"
              disabled={selectedSurvey ? false : true}
              accept=".csv"
              id="file"
              onChange={handleInputChange}
            />
          </div>
          {/* <div id="invalidMessages"></div> */}
        </Col>
        <Col xs="4"></Col>
        <Col xs="3">
          <Dropdown
            surveys={props.surveys}
            selectedSurvey={selectedSurvey && selectedSurvey}
            cycle={false}
            hancleChangeSurvey={hancleChangeSurvey}
          />
        </Col>
      </Row>
      <hr className="users-hr mt-4" style={{ background: "red !important" }} />

      {confirmation ? (
        <SweetAlert
          success
          title="Great !"
          confirmBtnBsStyle="info"
          onConfirm={() => setConfirmation(false)}
        >
          Users List has been Updated
        </SweetAlert>
      ) : null}

      {userList.length!==0 ? (
        <UserList userList={userList} OnUploadClick={OnUploadClick} isUpload={fileUpload} />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          {" "}
         {(selectedSurvey===null)?<h1>No Survey Selected</h1> :
       <>
         <h1>
            <i class="fas fa-box-open"></i>
          </h1>{" "}
          <h1>There is no user list in this survey</h1>
          <h4><code>Kindly follow the below steps to upload the User List</code></h4>
          <div style={{  display: "flex",
            flexDirection: "column",alignItems:"left",}}>
          <code>Step 1: Download the template -><CSV fileName="Format" textStyle={true} /> </code>
          <code>Step 2: Fill the template</code>
          <code>Step 3: Upload</code>
          </div>
          </>
          }
        </div>
      )}
    </div>
  );
};

export default UserMangement;
