import React, { useEffect } from 'react';
import { Route, Switch,Redirect } from 'react-router-dom';

import AdminLayout from '../layouts/Admin/Admin';

import LoginContainer from './Login';
const App = (props) => {
	useEffect(() => {
		const fetchToken = async () => {
			let userToken = await localStorage.getItem('x-auth');
			let role = JSON.parse(await localStorage.getItem('role'));
			try {
				userToken = JSON.parse(await localStorage.getItem('x-auth'))
					? JSON.parse(await localStorage.getItem('x-auth'))
					: null;
				props.restoreToken(userToken, role);
				if (userToken == null) {
					props.hist.push('/login');
				}
			} catch (e) {
				// Restoring token failed
			}
		};
		fetchToken();
	}, []);
	if(localStorage.getItem('x-auth')===null){
		<React.Fragment>
		<Route exact path="/login" component={LoginContainer} /> 
		<Route exact strict path="/" render={({location}) => {
			if (location.pathname === window.location.pathname) {
				return <Redirect to="/login"/>;
			}
			return null;
		}} />
	</React.Fragment>
	  }
	return (
				<Switch>
				<Route exact path="/login" component={LoginContainer} />
				<Route path="/" render={(props) => <AdminLayout {...props} />} />
			</Switch>
	);
};



export default App;