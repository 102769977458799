import React,{useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux'
import UserManagement from "../view/UsersManagement";
import { fetchSurvey } from '../store/actions/surveyList'
import {CSVConfig} from "../config/CSVConfig";

const UserMangementContainer = (props) => {
  const [loading, setLoading] = useState(true)
	const [isAuthenticated, setAuthenticated] = useState(() => {
		const token = localStorage.getItem("x-auth");
		return token !== null;
	});
	const Dispatch = useDispatch();
	
	
	useEffect(()=>{
		if(isAuthenticated){
			const loadSpots = async ()=> {
				setLoading(true);
				await Dispatch(fetchSurvey());
				setLoading(false)
			}
			loadSpots();
		}
	}, [Dispatch,isAuthenticated]);

	if(isAuthenticated===false){
		props.history.push("/login")
	}

    const surveyList = useSelector((state) => state.surveyList)
    var allActiveSurvey = surveyList && surveyList.surveyList.surveys && surveyList.surveyList.surveys.filter(e => e.active !== 0)

 
  return <UserManagement CSVConfig={CSVConfig} surveys={allActiveSurvey} />;
};

export default UserMangementContainer;
