import { requestApi } from "../../config/apiHandler"
import { signOut } from '../actions/auth';

export const fetchUsersStart = () => {
	return {
		type: 'FETCH_USERS_START',
	};
};

export const fetchUsersFail = (msg) => {
	return {
	  type: "FETCH_USERS_FAIL",
	  payload: msg,
	};
  };
  
  export const dismissError = () => {
	return {
	  type: "DISMISS_ERROR_TARGET",
	};
  };
  

export const fetchUsers = (survey_id) => {
	
	return async (dispatch) => {
		dispatch(fetchUsersStart());

		const response = await requestApi(`/users?survey_id=${survey_id}`, 'GET', {});
		if (response.code == 'success') {
			
			dispatch({
				type: 'FETCH_USERS_SUCCESS',
				payload: response.data.users
			});
	} else if (response.code == 'authn_fail') {
		dispatch(signOut());
	} else {
		dispatch({
			type: 'FETCH_USERS_FAIL',
			payload: response.message
		});
};
}
}

export const postUsersStart = () => {
	return {
		type: 'POST_USERS_START',
	};
};

export const postUsersFail = (msg) => {
	return {
	  type: "POST_USERS_FAIL",
	  payload: msg,
	};
  };

export const checkStatusStart = () => {
	return {
		type: 'CHECK_STATUS_START',
	};
};


export const postUsers = (users, survey_id) => {
	
	return async (dispatch) => {
		dispatch(postUsersStart());

        let data = {
            survey_id : survey_id,
            users : users
        }
		const response = await requestApi(`/users`, 'POST', JSON.stringify(data));
		if (response.code == 'success') {
			
			dispatch({
				type: 'POST_USERS_SUCCESS',
				payload: response.data.request_id
			});
	} else if (response.code == 'authn_fail') {
		dispatch(signOut());
	} else {
		dispatch({
			type: 'POST_USERS_FAIL',
			payload: response.message
		});
};
}
}
export const checkStatus = (request_id) => {
	
	return async (dispatch) => {
		dispatch(checkStatusStart());

		const response = await requestApi(`/requests/${request_id}`, 'GET', {});
		if (response.code == 'success') {
			
			dispatch({
				type: 'CHECK_STATUS_SUCCESS',
				payload: response.data.request.status
			});
	} else if (response.code == 'authn_fail') {
		dispatch(signOut());
	} else {
		dispatch({
			type: 'CHECK_STATUS_FAIL',
			payload: response.message
		});
};
}
}

// export const putUsersStart = () => {
// 	return {
// 		type: 'PUT_USERS_START',
// 	};
// };

// export const putUsersFail = (msg) => {
// 	return {
// 	  type: "PUT_USERS_FAIL",
// 	  payload: msg,
// 	};
//   };
// export const putUsers = (id, Data) => {
	
// 	return async (dispatch) => {
// 		dispatch(putUsersStart());

// 		const response = await requestApi(`/templates/${id}`, 'PUT',JSON.stringify(Data));
// 		if (response.code == 'success') {
			
// 			dispatch({
// 				type: 'PUT_USERS_SUCCESS',
// 				payload: response.data
// 			});
// 	} else if (response.code == 'authn_fail') {
// 		dispatch(signOut());
// 	} else {
// 		dispatch({
// 			type: 'PUT_USERS_FAIL',
// 			payload: response.message
// 		});
// };
// }
// }


